import React from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  Flex,
  Box,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
} from "@chakra-ui/core";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { Error } from "./style";
import { CSSTransition } from "react-transition-group";

const emailRegEx = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const flexSettings = {
  flex: "1",
  minW: ["250px", "250px", "250px", "400px"],
  maxW: ["250px", "400px"],
  w: "100%",
  color: "white",
  mx: ["auto", "6"],
  mb: "6",
};

const Email = ({ handleNext, user, handleBack }) => {
  const { action, state } = useStateMachine(updateAction);
  const { control, handleSubmit, errors, getValues } = useForm();
  const [start, setStart] = React.useState(false);

  const submitBack = () => {
    action(getValues());
    handleBack();
  };

  const onSubmit = (data) => {
    action(data);
    handleNext();
  };
  React.useEffect(() => {
    setStart(true);
    return () => {
      setStart(false);
    };
  }, [setStart]);
  return (
    <CSSTransition in={start} timeout={300} classNames="form" unmountOnExit>
      <div>
        <Flex maxW="950px" mx="auto">
          <Heading fontSize={["sm", "md", "xl"]} ml="10px">
            Adresa elektroničke pošte/promijenjena adresa elektroničke pošte:
          </Heading>
        </Flex>
        <Flex
          w="100%"
          justify="center"
          flexWrap="wrap"
          maxW="900px"
          mx="auto"
          mt="15px"
        >
          <Box {...flexSettings}>
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="email">
                Adresa elektroničke pošte
              </FormLabel>
              <Controller
                as={Input}
                borderColor={errors.hasOwnProperty("email") ? "red.500" : ""}
                control={control}
                name="email"
                color="black"
                placeholder="Email"
                defaultValue={state.data.email}
                rules={{
                  required: "Email je obvezno polje",
                  pattern: {
                    value: emailRegEx,
                    message: "Email adresa nije ispravna",
                  },
                }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="email" />
          </Box>
        </Flex>
        <Flex maxW="950px" mx="auto" justifyContent="flex-end">
          <Box m="15px">
            <Button
              onClick={submitBack}
              variantColor="gray"
              boxShadow="-5px -5px gray"
              mr="15px"
              border="0"
              _hover={{
                boxShadow: "0px 0px gray",
                backgroundColor: "gray.600",
                transform: "translateY(-5px)",
                color: "white",
              }}
            >
              Natrag
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              variantColor="green"
              boxShadow="-5px -5px green"
              border="0"
              _hover={{
                boxShadow: "0px 0px green",
                backgroundColor: "green.600",
                transform: "translateY(-5px)",
              }}
            >
              Sljedeći
            </Button>
          </Box>
        </Flex>
      </div>
    </CSSTransition>
  );
};

export default Email;
