import React from "react";
import { Box } from "@chakra-ui/core";
import { StateMachineProvider } from "little-state-machine";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Predlagatelj from "./Predlagatelj";
import OvlastenaOsoba from "./OvlastenaOsoba";
import Email from "./Email";
import LastStep from "./LastStep";
import "./FormStepper.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    textAlign: "center",
  },
}));

function getSteps() {
  return ["Korak 1", "Korak 2", "Korak 3"];
}

function getStepContent(step, handleNext, handleBack) {
  switch (step) {
    case 0:
      return <Predlagatelj handleNext={handleNext} />;
    case 1:
      return <OvlastenaOsoba handleNext={handleNext} handleBack={handleBack} />;
    case 2:
      return <Email handleNext={handleNext} handleBack={handleBack} />;

    default:
      return "Unknown step";
  }
}

export default function FormStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <div className={classes.root}>
      <StateMachineProvider>
        <Box maxW="1200px" mx="auto">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        <div>
          {activeStep === steps.length ? (
            <LastStep handleBack={handleBack} />
          ) : (
            <div>{getStepContent(activeStep, handleNext, handleBack)}</div>
          )}
        </div>
      </StateMachineProvider>
    </div>
  );
}
