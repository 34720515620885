import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { useForm } from "react-hook-form";
import { Flex, Box, Heading, Button, Text, Spinner } from "@chakra-ui/core";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { b64toBlob } from "../helpers/functions";
import { CSSTransition } from "react-transition-group";

const LastStep = ({ handleBack }) => {
  const { action, state } = useStateMachine(updateAction);
  const { handleSubmit, getValues } = useForm();
  const submitBack = () => {
    action(getValues());
    handleBack();
  };
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [start, setStart] = React.useState(false);

  const onSubmit = () => {
    setIsDownloading(true);
    axios
      .post("https://www.pdf-api.accountant.hr/create-pdf", {
        name: "obrazacEmail",
        data: state.data,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(
          b64toBlob(res.data, "application/pdf")
        );
        saveAs(
          url,
          `${state.data.oibTvrtke}_OBRAZAC_ZAHTJEVA_ZA_UPIS/PROMJENU_ADRESE_ELEKTRONICKE_POSTE.pdf`
        );
        setIsDownloading(false);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    setStart(true);
    return () => {
      setStart(false);
    };
  }, [setStart]);
  return (
    <CSSTransition in={start} timeout={300} classNames="form" unmountOnExit>
      <div>
        <Flex maxW="950px" mx="auto" flexDir="column">
          <Heading fontSize={["lg", "xl"]} ml="10px" mx="auto">
            Dovršeno
          </Heading>
          <Box
            as={CheckCircleIcon}
            fill="green.500"
            color="green.500"
            mx="auto"
            style={{ width: "5rem", height: "5rem" }}
          />
          <Text textAlign="center">
            Preuzmite PDF ili se vratite natrag na uređivanje.
          </Text>
        </Flex>

        <Flex
          w="100%"
          justify="space-between"
          flexWrap="wrap"
          maxW="900px"
          mx="auto"
          mt="15px"
          justifyContent="center"
        >
          {isDownloading ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          ) : (
            <Box m="15px">
              <Button
                onClick={submitBack}
                variantColor="gray"
                boxShadow="-5px -5px gray"
                mr="15px"
                border="0"
                _hover={{
                  boxShadow: "0px 0px gray",
                  backgroundColor: "gray.600",
                  transform: "translateY(-5px)",
                  color: "white",
                }}
              >
                Natrag
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                variantColor="green"
                boxShadow="-5px -5px green"
                border="0"
                _hover={{
                  boxShadow: "0px 0px green",
                  backgroundColor: "green.600",
                  transform: "translateY(-5px)",
                }}
              >
                Preuzmi PDF
              </Button>
            </Box>
          )}
        </Flex>
        <Flex maxW="950px" mx="auto" flexDir="column">
          <Text textAlign="center" mt="20" color="blue.600" fontSize="18px">
            Izrada:{" "}
            <Box
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              textDecor="underline"
              href="http://accountant.hr"
            >
              accountant.hr
            </Box>
          </Text>
        </Flex>
      </div>
    </CSSTransition>
  );
};

export default LastStep;
