import React from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  Flex,
  Box,
  Heading,
  Input,
  Button,
  FormLabel,
  FormControl,
  Divider,
  CloseButton,
  useToast,
} from "@chakra-ui/core";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import NumberFormat from "react-number-format";
import { Error } from "./style";
import { CSSTransition } from "react-transition-group";

const flexSettings = {
  flex: "1",
  minW: ["250px", "250px", "250px", "400px"],
  maxW: ["250px", "400px"],
  w: "100%",
  color: "white",
  mx: ["auto", "6"],
  mb: "6",
};

const OvlastenaOsoba = ({ handleNext, user, handleBack }) => {
  const { action, state } = useStateMachine(updateAction);
  const { control, handleSubmit, errors, getValues } = useForm({
    defaultValues: {
      ovlasteneOsobe: state.data.ovlasteneOsobe,
    },
  });
  const [start, setStart] = React.useState(false);
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "ovlasteneOsobe", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });
  const submitBack = () => {
    action(getValues());
    handleBack();
  };
  const toast = useToast();

  const onSubmit = (data) => {
    if (data.ovlasteneOsobe === undefined || data.ovlasteneOsobe.length < 1) {
      toast({
        title: "Greška.",
        description: "Dodajte barem jednu ovlaštenu osobu",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      action(data);
      handleNext();
    }
  };
  React.useEffect(() => {
    setStart(true);
    return () => {
      setStart(false);
    };
  }, [setStart]);
  return (
    <CSSTransition in={start} timeout={300} classNames="form" unmountOnExit>
      <div>
        {fields.map((field, index) => {
          return (
            <div key={field.imePrezime + index}>
              <Flex maxW="950px" mx="auto">
                <Heading fontSize={["sm", "md", "xl"]} ml="10px">
                  Podaci o ovlaštenoj osobi predlagatelja:
                </Heading>
              </Flex>
              <Flex
                w="100%"
                justify="space-between"
                flexWrap="wrap"
                maxW="900px"
                mx="auto"
                mt="15px"
              >
                <Box {...flexSettings}>
                  <FormControl isRequired>
                    <FormLabel
                      color="black"
                      htmlFor={`ovlasteneOsobe[${index}].imePrezime`}
                    >
                      Ime i prezime
                    </FormLabel>
                    <Controller
                      as={Input}
                      borderColor={
                        errors.hasOwnProperty(
                          `ovlasteneOsobe[${index}].imePrezime`
                        )
                          ? "red.500"
                          : ""
                      }
                      control={control}
                      color="black"
                      name={`ovlasteneOsobe[${index}].imePrezime`}
                      placeholder="Ime i prezime"
                      defaultValue={field.imePrezime}
                      rules={{ required: "Ime i prezime je obvezno polje" }}
                    />
                  </FormControl>
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name={`ovlasteneOsobe[${index}].imePrezime`}
                  />
                </Box>

                <Box {...flexSettings}>
                  <FormControl isRequired>
                    <FormLabel
                      color="black"
                      htmlFor={`ovlasteneOsobe[${index}].funkcija`}
                    >
                      Funkcija
                    </FormLabel>
                    <Controller
                      as={Input}
                      borderColor={
                        errors.hasOwnProperty(
                          `ovlasteneOsobe[${index}].funkcija`
                        )
                          ? "red.500"
                          : ""
                      }
                      control={control}
                      color="black"
                      name={`ovlasteneOsobe[${index}].funkcija`}
                      placeholder="Funkcija"
                      defaultValue={field.funkcija}
                      rules={{ required: "Funkcija je obvezno polje" }}
                    />
                  </FormControl>
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name={`ovlasteneOsobe[${index}].funkcija`}
                  />
                </Box>
                <Box {...flexSettings}>
                  <FormControl isRequired>
                    <FormLabel
                      color="black"
                      htmlFor={`ovlasteneOsobe[${index}].oibOsobe`}
                    >
                      OIB
                    </FormLabel>
                    <Controller
                      as={
                        <NumberFormat
                          allowLeadingZeros
                          decimalSeparator={false}
                          type="text"
                          customInput={Input}
                        ></NumberFormat>
                      }
                      borderColor={
                        errors.hasOwnProperty(
                          `ovlasteneOsobe[${index}].oibOsobe`
                        )
                          ? "red.500"
                          : ""
                      }
                      control={control}
                      color="black"
                      name={`ovlasteneOsobe[${index}].oibOsobe`}
                      placeholder="OIB"
                      maxLength={11}
                      defaultValue={field.oibOsobe}
                      rules={{
                        required: "OIB je obvezan",
                        minLength: {
                          value: 11,
                          message: "OIB mora sadržavati najmanje 11 znakova",
                        },
                      }}
                    />
                  </FormControl>
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name={`ovlasteneOsobe[${index}].oibOsobe`}
                  />
                </Box>
                <Box {...flexSettings}>
                  <FormControl isRequired>
                    <FormLabel
                      color="black"
                      htmlFor={`ovlasteneOsobe[${index}].osobaPrebivaliste`}
                    >
                      Prebivalište
                    </FormLabel>
                    <Controller
                      as={Input}
                      borderColor={
                        errors.hasOwnProperty(
                          `ovlasteneOsobe[${index}].osobaPrebivaliste`
                        )
                          ? "red.500"
                          : ""
                      }
                      control={control}
                      color="black"
                      name={`ovlasteneOsobe[${index}].osobaPrebivaliste`}
                      placeholder="Prebivalište"
                      defaultValue={field.osobaPrebivaliste}
                      rules={{ required: "Prebivalište je obvezno" }}
                    />
                  </FormControl>
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name={`ovlasteneOsobe[${index}].osobaPrebivaliste`}
                  />
                </Box>
                <Box
                  d="flex"
                  alignItems="center"
                  w={["100%"]}
                  justifyContent="center"
                  mx="auto"
                >
                  <Button
                    type="button"
                    w="30px"
                    variantColor="red"
                    //boxShadow="-5px -5px red"
                    border="0"
                    m="15px"
                    _hover={{
                      boxShadow: "0px 0px red",
                      backgroundColor: "red.600",
                      transform: "translateY(-5px)",
                    }}
                    onClick={() => remove(index)}
                  >
                    <CloseButton size="sm" />
                  </Button>
                </Box>
                <Divider
                  w={["90%"]}
                  mx="auto"
                  d={["block", "block", "block", "none"]}
                  borderColor="black.300"
                ></Divider>
              </Flex>
            </div>
          );
        })}
        <Flex maxW="865px" mx="auto" justifyContent="flex-start">
          <Button
            onClick={() =>
              append({ imePrezime: "", prebivaliste: "", oib: "" })
            }
            variantColor="gray"
            boxShadow="-5px -5px gray"
            border="0"
            m="15px"
            mx={["auto", "auto", "auto", "initial"]}
            _hover={{
              boxShadow: "0px 0px gray",
              backgroundColor: "gray.600",
              transform: "translateY(-5px)",
              color: "white",
            }}
          >
            +
          </Button>
        </Flex>
        <Flex maxW="950px" mx="auto" justifyContent="flex-end">
          <Box m="15px">
            <Button
              onClick={submitBack}
              variantColor="gray"
              boxShadow="-5px -5px gray"
              mr="15px"
              border="0"
              _hover={{
                boxShadow: "0px 0px gray",
                backgroundColor: "gray.600",
                transform: "translateY(-5px)",
                color: "white",
              }}
            >
              Natrag
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              variantColor="green"
              boxShadow="-5px -5px green"
              border="0"
              _hover={{
                boxShadow: "0px 0px green",
                backgroundColor: "green.600",
                transform: "translateY(-5px)",
              }}
            >
              Sljedeći
            </Button>
          </Box>
        </Flex>
      </div>
    </CSSTransition>
  );
};

export default OvlastenaOsoba;
