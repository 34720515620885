import React from "react";
import { createStore } from "little-state-machine";
import { Flex, Heading, PseudoBox, CloseButton } from "@chakra-ui/core";
import FormStepper from "./FormStepper";

createStore({
  data: {
    email: "",
    funkcija: "",
    imePrezime: "",
    nazivTvrtke: "",
    newEmail: "",
    oib: "",
    oibOsobe: "",
    oibTvrtke: "",
    osnivac: "",
    osobaPrebivaliste: "",
    poslovniBrojTt: "",
    prebivaliste: "",
    sjediste: "",
    trgovackiSud: "",
    osnivaci: [{ imePrezime: "", prebivaliste: "", oib: "" }],
    ovlasteneOsobe: [
      { imePrezime: "", oibOsobe: "", funkcija: "", osobaPrebivaliste: "" },
    ],
  },
});

const CustomToast = () => {
  const [disabled, setDisabled] = React.useState(false);
  return (
    <PseudoBox d={disabled ? "none" : "flex"} justifyContent="center">
      <PseudoBox
        pos="fixed"
        d="flex"
        backgroundColor="gray.500"
        opacity="95%"
        color="white"
        borderRadius="10px"
        p="15px"
        m="15px"
        bottom="0"
        maxW="900px"
        cursor="pointer"
        onClick={() => setDisabled(true)}
        boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
        fontSize={["0.6em", "12px"]}
        textAlign={["justify", "initial"]}
      >
        Obrada podataka: ova aplikacija ne sprema upisane podatke. Upisani
        podaci se obrađuju samo i isključivo pri izradi PDF dokumenta obrasca,
        koji također nije sačuvan osim u slučaju da ga korisnik sam preuzme na
        svoje računalo. Podaci o pravnoj osobi preuzeti su sa Sudskog registra.
        <CloseButton mt="-4px" ml="4px" size="sm" />
      </PseudoBox>
    </PseudoBox>
  );
};
const Form = () => {
  return (
    <div>
      <Flex
        w="100%"
        justify="space-between"
        flexWrap="wrap"
        maxW="1200px"
        mx="auto"
      >
        <Heading
          fontSize={["lg", "lg", "lg", "xl", "2xl"]}
          //fontSize={{base: "200px", sm: "lg", md: "lg", lg: "xl", xl: "2xl"}}
          mx="0.5rem"
          textAlign="center"
        >
          Obrazac zahtjeva za upis/promjenu adrese elektroničke pošte
        </Heading>
      </Flex>
      <FormStepper />
      <CustomToast />
    </div>
  );
};

export default Form;
