import React from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  Flex,
  Box,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  Divider,
  Spinner,
  CloseButton,
  useToast,
} from "@chakra-ui/core";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import NumberFormat from "react-number-format";
import { Error } from "./style";
import { CSSTransition } from "react-transition-group";
import axios from "axios";

const config = {
  headers: {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCR_KEY,
  },
};

function pad(num, size) {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

const flexSettings = {
  flex: "1",
  minW: ["250px", "250px", "250px", "400px"],
  maxW: ["250px", "400px"],
  w: "100%",
  color: "white",
  mx: ["auto", "6"],
  mb: "6",
};
const osnivacSettings = {
  flex: "1",
  minW: ["220px", "220px", "220px"],
  maxW: ["200px"],
  w: "100%",
  color: "white",
  mx: ["auto", "6"],
  mb: "6",
};
const Predlagatelj = ({ handleNext, user, handleBack }) => {
  const { action, state } = useStateMachine(updateAction);
  const {
    control,
    handleSubmit,
    errors,
    getValues,
    setValue,
    trigger,
  } = useForm({
    defaultValues: { osnivaci: state.data.osnivaci },
  });
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "osnivaci", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const [gettingData, setGettingData] = React.useState(false);
  const toast = useToast();
  const [start, setStart] = React.useState(false);
  const onSubmit = (data) => {
    if (data.osnivaci === undefined || data.osnivaci.length < 1) {
      toast({
        title: "Greška.",
        description: "Dodajte barem jednog osnivača",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      setStart(false);
      action(data);
      handleNext();
    }
  };
  const handleGetData = async (values) => {
    const resVal = await trigger("getByOib");
    if (resVal) {
      setGettingData(true);
      axios
        .get(
          `https://sudreg-api.pravosudje.hr/javni/subjekt_detalji?tipIdentifikatora=oib&identifikator=${values.getByOib}&expand_relations=true`,
          config
        )
        .then(function (response) {
          const {
            sjedista,
            skracene_tvrtke,
            mbs,
            oib,
            sud_nadlezan,
          } = response.data;

          const adresa = `${sjedista[0].ulica}${
            sjedista[0].kucni_broj !== undefined ? " " : ""
          }${
            sjedista[0].kucni_broj !== undefined ? sjedista[0].kucni_broj : ""
          }, ${sjedista[0].naziv_naselja}`;
          const mbsP = pad(mbs, 9);

          action({
            trgovackiSud: sud_nadlezan.naziv,
            sjediste: adresa,
            oibTvrtke: pad(oib, 11),
            mbs: mbsP,
            nazivTvrtke: skracene_tvrtke[0].ime,
            euid: "HRSR." + mbsP,
          });
          setValue("trgovackiSud", sud_nadlezan.naziv);
          setValue("sjediste", adresa);
          setValue("oibTvrtke", pad(oib, 11));
          setValue("mbs", mbsP);
          setValue("nazivTvrtke", skracene_tvrtke[0].ime);
          setValue("euid", "HRSR." + mbsP);
          console.log(pad(oib, 11));
          setGettingData(false);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  };
  React.useEffect(() => {
    setStart(true);
    return () => {
      setStart(false);
    };
  }, [setStart]);
  return (
    <CSSTransition in={start} timeout={300} classNames="form" unmountOnExit>
      <div>
        <Flex maxW="950px" mx="auto">
          <Heading fontSize={["sm", "md", "xl"]} ml="10px">
            Zahtjev za upis/promjenu adrese elektroničke pošte predlagatelj:
          </Heading>
        </Flex>
        <Flex maxW="950px" mx="auto">
          <Heading fontSize={["sm", "md", "lg"]} ml="10px">
            Osnovni podaci
          </Heading>
        </Flex>
        <Flex
          w="100%"
          justify="flex-start"
          flexWrap="wrap"
          maxW="900px"
          mx="auto"
          mt="15px"
        >
          <Box {...flexSettings}>
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="getByOib">
                OIB Tvrtke
              </FormLabel>
              <Controller
                as={
                  <NumberFormat
                    allowLeadingZeros
                    decimalSeparator={false}
                    type="text"
                    customInput={Input}
                    maxLength={11}
                  ></NumberFormat>
                }
                borderColor={errors.hasOwnProperty("getByOib") && "red.500"}
                control={control}
                color="black"
                name="getByOib"
                placeholder="OIB tvrtke"
                defaultValue={state.data.getByOib}
                rules={{
                  required: "OIB tvrtke je obvezan",
                  minLength: {
                    value: 11,
                    message: "OIB tvrtke mora sadržavati najmanje 11 znakova",
                  },
                }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="getByOib" />
          </Box>
          <Box
            d="flex"
            alignItems="center"
            mx={["auto"]}
            ml="50px"
            w={["250px"]}
            justifyContent="center"
          >
            {gettingData ? (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            ) : (
              <Button
                onClick={() => handleGetData(getValues())}
                variantColor="green"
                boxShadow="-5px -5px green"
                border="0"
                m="15px"
                _hover={{
                  boxShadow: "0px 0px green",
                  backgroundColor: "green.600",
                  transform: "translateY(-5px)",
                }}
              >
                Dohvati podatke
              </Button>
            )}
          </Box>
        </Flex>
        <Flex
          w="100%"
          justify="space-between"
          flexWrap="wrap"
          maxW="900px"
          mx="auto"
          mt="15px"
        >
          <Box {...flexSettings}>
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="trgovackiSud">
                Trgovački sud
              </FormLabel>
              <Controller
                as={Input}
                borderColor={
                  errors.hasOwnProperty("trgovackiSud") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="trgovackiSud"
                placeholder="Trgovački sud"
                defaultValue={state.data.trgovackiSud}
                rules={{ required: "Trgovački sud je obvezno polje" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="trgovackiSud" />
          </Box>
          <Box {...flexSettings}>
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="mbs">
                MBS
              </FormLabel>
              <Controller
                as={
                  <NumberFormat
                    allowLeadingZeros
                    decimalSeparator={false}
                    type="text"
                    customInput={Input}
                    maxLength={11}
                  ></NumberFormat>
                }
                borderColor={errors.hasOwnProperty("mbs") ? "red.500" : ""}
                control={control}
                color="black"
                name="mbs"
                placeholder="mbs tvrtke"
                defaultValue={state.data.mbs}
                rules={{
                  required: "MBS je obvezan",
                  minLength: {
                    value: 8,
                    message: "MBS mora sadržavati najmanje 8 znakova",
                  },
                }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="mbs" />
          </Box>
          <Box {...flexSettings}>
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="nazivTvrtke">
                Naziv tvrtke
              </FormLabel>
              <Controller
                as={Input}
                borderColor={
                  errors.hasOwnProperty("nazivTvrtke") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="nazivTvrtke"
                placeholder="Naziv tvrtke"
                defaultValue={state.data.nazivTvrtke}
                rules={{ required: "Naziv tvrtke je obvezan" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="nazivTvrtke" />
          </Box>
          <Box {...flexSettings}>
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="oibTvrtke">
                OIB
              </FormLabel>
              <Controller
                as={
                  <NumberFormat
                    allowLeadingZeros
                    decimalSeparator={false}
                    type="text"
                    customInput={Input}
                    maxLength={11}
                  ></NumberFormat>
                }
                borderColor={
                  errors.hasOwnProperty("oibTvrtke") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="oibTvrtke"
                placeholder="OIB tvrtke"
                defaultValue={state.data.oibTvrtke}
                rules={{
                  required: "OIB tvrtke je obvezan",
                  minLength: {
                    value: 11,
                    message: "OIB tvrtke mora sadržavati najmanje 11 znakova",
                  },
                }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="oibTvrtke" />
          </Box>
          <Box {...flexSettings}>
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="sjediste">
                Sjedište
              </FormLabel>
              <Controller
                as={Input}
                borderColor={errors.hasOwnProperty("sjediste") ? "red.500" : ""}
                control={control}
                color="black"
                name="sjediste"
                placeholder="Sjedište"
                defaultValue={state.data.sjediste}
                rules={{ required: "Sjedište je obvezno" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="sjediste" />
          </Box>
          <Box {...flexSettings}>
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="euid">
                EUID
              </FormLabel>
              <Controller
                as={Input}
                borderColor={errors.hasOwnProperty("euid") ? "red.500" : ""}
                control={control}
                color="black"
                name="euid"
                placeholder="Naziv tvrtke"
                defaultValue={state.data.euid}
                rules={{ required: "Naziv tvrtke je obvezan" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="euid" />
          </Box>
          <Divider mx="auto" w={["90%", "90%"]} borderColor={"black.200"} />
          <Flex w="100%" mx="auto" mb="15px">
            <Heading fontSize={["sm", "md", "lg"]} ml="10px">
              Osnivači
            </Heading>
          </Flex>
          {fields.map((field, index) => {
            return (
              <Flex
                flexWrap="wrap"
                justifyContent="center"
                w={["90%", "100%"]}
                mx="auto"
                mb={["10px"]}
                key={field.imePrezime + index}
              >
                <Box {...osnivacSettings}>
                  <FormControl isRequired>
                    <FormLabel
                      color="black"
                      htmlFor={`osnivaci[${index}].imePrezime`}
                    >
                      Osnivači ili ime i prezime
                    </FormLabel>
                    <Controller
                      as={Input}
                      borderColor={
                        errors.hasOwnProperty(`osnivaci[${index}].imePrezime`)
                          ? "red.500"
                          : ""
                      }
                      control={control}
                      color="black"
                      name={`osnivaci[${index}].imePrezime`}
                      placeholder="Osnivač"
                      defaultValue={field.imePrezime}
                      rules={{ required: "Ime prezime je obvezno polje" }}
                    />
                  </FormControl>
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name={`osnivaci[${index}].imePrezime`}
                  />
                </Box>
                <Box {...osnivacSettings}>
                  <FormControl isRequired>
                    <FormLabel
                      color="black"
                      htmlFor={`osnivaci[${index}].prebivaliste`}
                    >
                      Prebivalište
                    </FormLabel>
                    <Controller
                      as={Input}
                      borderColor={
                        errors.hasOwnProperty(`osnivaci[${index}].prebivaliste`)
                          ? "red.500"
                          : ""
                      }
                      control={control}
                      color="black"
                      name={`osnivaci[${index}].prebivaliste`}
                      placeholder="Prebivalište"
                      defaultValue={field.prebivaliste}
                      rules={{ required: "Prebivalište je obvezno" }}
                    />
                  </FormControl>
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name={`osnivaci[${index}].prebivaliste`}
                  />
                </Box>
                <Box {...osnivacSettings}>
                  <FormControl isRequired>
                    <FormLabel color="black" htmlFor={`osnivaci[${index}].oib`}>
                      OIB fizičke osobe
                    </FormLabel>
                    <Controller
                      as={
                        <NumberFormat
                          allowLeadingZeros
                          decimalSeparator={false}
                          type="text"
                          customInput={Input}
                          maxLength={11}
                        ></NumberFormat>
                      }
                      borderColor={
                        errors.hasOwnProperty(`osnivaci[${index}].oib`)
                          ? "red.500"
                          : ""
                      }
                      control={control}
                      color="black"
                      name={`osnivaci[${index}].oib`}
                      placeholder="OIB fizičke osobe"
                      defaultValue={field.oib}
                      rules={{
                        required: "OIB je obvezan",
                        minLength: {
                          value: 11,
                          message: "OIB mora sadržavati najmanje 11 znakova",
                        },
                      }}
                    />
                  </FormControl>
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name={`osnivaci[${index}].oib`}
                  />
                </Box>
                <Box
                  d="flex"
                  alignItems="center"
                  w={["100%", "70px"]}
                  justifyContent="center"
                >
                  <Button
                    type="button"
                    variantColor="red"
                    w="30px"
                    //boxShadow="-5px -5px red"
                    border="0"
                    m="15px"
                    _hover={{
                      boxShadow: "0px 0px red",
                      backgroundColor: "red.600",
                      transform: "translateY(-5px)",
                    }}
                    onClick={() => remove(index)}
                  >
                    <CloseButton size="sm" />
                  </Button>
                </Box>
                <Divider
                  mx="auto"
                  w={["100%", "90%"]}
                  d={["block", "block", "block", "none"]}
                  borderColor="black.300"
                ></Divider>
              </Flex>
            );
          })}
        </Flex>
        <Flex maxW="865px" mx="auto" justifyContent="flex-start">
          <Button
            onClick={() =>
              append({ imePrezime: "", prebivaliste: "", oib: "" })
            }
            variantColor="gray"
            boxShadow="-5px -5px gray"
            border="0"
            m="15px"
            mx={["auto", "auto", "auto", "initial"]}
            _hover={{
              boxShadow: "0px 0px gray",
              backgroundColor: "gray.600",
              transform: "translateY(-5px)",
              color: "white",
            }}
          >
            +
          </Button>
        </Flex>
        <Flex maxW="950px" mx="auto" justifyContent="flex-end">
          <Button
            onClick={handleSubmit(onSubmit)}
            variantColor="green"
            boxShadow="-5px -5px green"
            border="0"
            m="15px"
            _hover={{
              boxShadow: "0px 0px green",
              backgroundColor: "green.600",
              transform: "translateY(-5px)",
            }}
          >
            Sljedeći
          </Button>
        </Flex>
      </div>
    </CSSTransition>
  );
};

export default Predlagatelj;
